.background {
  background: url(images/bg.jpg) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.form-container {
  width: 600px;
}

.left-background {
  background: url(images/bg-left.jpg) no-repeat center center fixed; 
}
@tailwind base;
@tailwind components;
@tailwind utilities;
